import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Helmet } from "react-helmet"

const ResourcesEvents = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulResources {
        totalCount
        edges {
          node {
            documents {
              file {
                url
              }
              title
            }
          }
        }
      }
    }
  `)

  const { documents } = data.allContentfulResources.edges[0].node

  return (
    <>
      <Helmet>
        <title>Resources & Events | Fraser Community Child Care Centre & Out of School Care</title>
        <link rel="canonical" href="https://frasercommunitychildcarecenter.com/resources-events" />
      </Helmet>
      <div className="grid">
        <div className="grid__item grid__item--center section">
          <h1 className="heading heading--section heading--no-skew text--centered">Resources & Events</h1>
          <div className="documents">
            {
              documents.map(d => (
                <div className="document">
                  <h2 className="heading heading--subsection heading--no-skew">{d.title}</h2>
                  <a href={d.file.url}>
                    <button className="button button--red">Download</button>
                  </a>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default ResourcesEvents
